<template>
  <div
    style="
      overflow: hidden;
      line-height: 1;
      background-color: #f9731c;
      padding: 0 15px 40px;
      box-sizing: border-box;
    "
    v-if="bargainGoodsItem"
  >
    <!-- 商品详情 -->
    <!-- 已完成 -->
    <div
      class="help-friend-bargain"
      v-if="
        bargainGoodsItem.bargin_status === 'success' ||
        bargainGoodsItem.bargin_status === 'deliver'
      "
    >
      <div class="goods-box">
        <img :src="bargainGoodsItem.bargin_product.main_image" alt="" />
        <div class="goods-description">
          <div class="goods-title">
            {{ bargainGoodsItem.bargin_product.goods_title }}
          </div>
          <div class="goods-tag">
            <div class="goods-tag-item">包邮到家</div>
            <div class="goods-tag-item">正品保障</div>
          </div>
        </div>
      </div>
      <div class="progress-text">
        <img
          style="width: 294px; height: 17px; margin: 4px 0 -2px"
          src="@/assets/bargain/tjf_kjmfn_hykjcg_kjcg_img.png"
          alt=""
        />
      </div>
      <van-progress
        :percentage="
          (
            (bargainGoodsItem.done_price / bargainGoodsItem.total_price) *
            100
          ).toFixed(2)
        "
        :show-pivot="false"
        track-color="#FFDBB4"
        stroke-width="12px"
        color="linear-gradient(90deg, #ff973c, #ff6836)"
      />
      <wx-open-launch-app
        @error="handleErrorFn"
        @launch="handleLaunchFn"
        v-clipboard:copy="bargainGoodsItem.token"
        id="launch-btn"
        appid="wx1ce7e0fec3828ebc"
        style="margin: 20px 0 0; width: 325px; height: 47px"
      >
        <script type="text/wxtag-template">
          <div  style="
          line-height: 47px;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          width: 325px;
          height: 47px;
          background: linear-gradient(180deg, #fd693b, #f54627);
          border-radius: 23px;
          ">我也要免费拿</div>
        </script>
      </wx-open-launch-app>
    </div>
    <!-- 已过期 -->
    <div
      class="help-friend-bargain"
      v-else-if="bargainGoodsItem.bargin_status === 'expired'"
    >
      <div class="goods-box">
        <img :src="bargainGoodsItem.bargin_product.main_image" alt="" />
        <div class="goods-description">
          <div class="goods-title">
            {{ bargainGoodsItem.bargin_product.goods_title }}
          </div>
          <div class="goods-tag">
            <div class="goods-tag-item">包邮到家</div>
            <div class="goods-tag-item">正品保障</div>
          </div>
        </div>
      </div>
      <div class="progress-text">
        <img
          style="width: 182px; height: 48px; margin: -5px 0"
          src="@/assets/bargain/tjf_kjmfn_hykjgq_kjygq_img.png"
          alt=""
        />
      </div>
      <van-progress
        :percentage="
          (
            (bargainGoodsItem.done_price / bargainGoodsItem.total_price) *
            100
          ).toFixed(2)
        "
        :show-pivot="false"
        track-color="#E5E5E5"
        stroke-width="12px"
        color="#9C9C9C"
      />
      <wx-open-launch-app
        @error="handleErrorFn"
        @launch="handleLaunchFn"
        v-clipboard:copy="bargainGoodsItem.token"
        id="launch-btn"
        appid="wx1ce7e0fec3828ebc"
        style="margin: 20px 0 0; width: 325px; height: 47px"
      >
        <script type="text/wxtag-template">
          <div style="
          line-height: 47px;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          width: 325px;
          height: 47px;
          background: linear-gradient(180deg, #fd693b, #f54627);
          border-radius: 23px;">前往免费拿</div>
        </script>
      </wx-open-launch-app>
    </div>
    <!-- 进行中 -->
    <div class="help-friend-bargain" v-else>
      <div class="goods-box">
        <img :src="bargainGoodsItem.bargin_product.main_image" alt="" />
        <div class="goods-description">
          <div class="goods-title">
            {{ bargainGoodsItem.bargin_product.goods_title }}
          </div>
          <div class="goods-tag">
            <div class="goods-tag-item">包邮到家</div>
            <div class="goods-tag-item">正品保障</div>
          </div>
          <div class="remaining-time">
            <uni-countdown
              :showTian="false"
              :show-day="false"
              :day="bargainGoodsItem.day"
              :hour="bargainGoodsItem.hour"
              :minute="bargainGoodsItem.min"
              :second="bargainGoodsItem.afterMin"
              splitorColor="#795E40"
              backgroundColor="#795E40"
              color="#FFFFFF"
            ></uni-countdown>
            <span>后砍价过期</span>
          </div>
        </div>
      </div>
      <div class="progress-text">
        已砍<span>{{ bargainGoodsItem.done_price }}</span
        >元，仅差<span
          >{{
            (
              100 -
              (bargainGoodsItem.done_price / bargainGoodsItem.total_price) * 100
            ).toFixed(2)
          }}%</span
        >
      </div>
      <van-progress
        :percentage="
          (
            (bargainGoodsItem.done_price / bargainGoodsItem.total_price) *
            100
          ).toFixed(2)
        "
        :show-pivot="false"
        track-color="#FFDBB4"
        stroke-width="12px"
        color="linear-gradient(90deg, #ff973c, #ff6836)"
      />
      <wx-open-launch-app
        @error="handleErrorFn"
        @launch="handleLaunchFn"
        v-clipboard:copy="bargainGoodsItem.token"
        id="launch-btn"
        appid="wx1ce7e0fec3828ebc"
        style="margin: 20px 0 0; width: 325px; height: 47px"
      >
        <script type="text/wxtag-template">
          <div style="
          line-height: 47px;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          width: 325px;
          height: 47px;
          background: linear-gradient(180deg, #fd693b, #f54627);
          border-radius: 23px;">帮好友砍一刀</div>
        </script>
      </wx-open-launch-app>
      <div class="tip">
        <span>还剩</span>
        <uni-countdown
          :showTian="false"
          :show-day="false"
          :day="bargainGoodsItem.day"
          :hour="bargainGoodsItem.hour"
          :minute="bargainGoodsItem.min"
          :second="bargainGoodsItem.afterMin"
          splitorColor="#444444"
          backgroundColor="#FFFFFF"
          color="#444444"
        ></uni-countdown>
        <span>过期，快来砍价吧～</span>
      </div>
    </div>
    <!-- 砍价记录 -->
    <div class="bargain-record">
      <div class="bargain-record-head-title">砍价记录</div>
      <div class="bargain-record-list">
        <div
          class="record-list-item"
          v-for="(item, index) in bargainGoodsItem.records"
          :key="index"
        >
          <img v-if="item.user" :src="item.user.avatar" class="friend-image" />
          <div class="friend-information" v-if="item.user">
            <span class="friend-name">{{ item.user.nickname }}</span>
            <span style="font-size: 12px">使用砍价道具狂砍一刀</span>
          </div>
          <div class="bargain-moeny">
            <img src="@/assets/bargain/tjf_kjmfn_kjxq_kjjl_dao_icon.png" />
            <span>{{ item.bargin_price }}元</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 商品列表 -->
    <div class="forfreeList" v-if="bargainList.length > 0">
      <div class="goods-list">
        <div
          class="goods-item"
          v-for="(item, index) in bargainList"
          :key="index"
        >
          <div class="goods-volume">
            已送出<span>{{ item.volume }}</span
            >件
          </div>
          <div class="goods-title">
            {{ item.goods_title }}
          </div>
          <img class="goods-image" :src="item.main_image" alt="" />
          <div
            class="forfree-take"
            hover-class="forfree-take-hover"
            hover-stay-time="50"
          >
            点击免费拿
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueClipboards from "vue-clipboards";
import uniCountdown from "./uni-countdown.vue";
import { Progress } from "vant";
import { BarginItem, BarginProductIndex } from "@/api/bargain";
import { getConfig } from "@/api/wxconfig";
Vue.use(Progress).use(VueClipboards);
export default {
  components: {
    uniCountdown,
  },

  computed: {},

  data() {
    return {
      bargainList: [],
      bargainGoodsItem: null,
    };
  },
  created() {
    this.BarginProductIndex();
    this.BarginItem(this.$route.params.id);
    this.getConfig();
  },
  methods: {
    // 监听error 函数
    handleErrorFn(e) {
      // 跳转失败
      this.$router.push("/home_moblie");
    },

    //监听成功success 函数
    handleLaunchFn(e) {
      // 跳转成功
    },

    //获取wx数据
    getConfig() {
      getConfig({
        url: `http ://www.taojiefang.cn/help-bargain/${this.$route.params.id}`,
        apis: ["checkJsApi"],
        open_tags: ["wx-open-launch-app"],
      }).then((res) => {
        if (res.errCode === 0) {
          this.$wechat.config(res.data);
          this.$wechat.ready(() => {
            this.$wechat.checkJsApi({
              jsApiList: ["wx-open-launch-app"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function (res) {
                var btn = document.getElementById("launch-btn");
                btn.addEventListener("launch", function (e) {});
                btn.addEventListener("error", function (e) {});
              },
              fail: (err) => {},
            });
          });
        }
      });
    },

    //获取砍价商品列表
    BarginProductIndex() {
      BarginProductIndex({
        page: 1,
      }).then((res) => {
        if (res.errCode == 0) {
          this.bargainList = res.data;
        }
      });
    },

    //获取砍价商品详情
    BarginItem(bargin_id) {
      BarginItem({
        bargin_id,
      }).then((res) => {
        if (res.errCode == 0) {
          //获取两个时间戳的差
          let timestamp = Math.round(new Date());
          //ios无法识别‘-’所以替换一下
          this.timediff(
            (new Date(res.data.expired_at.replace(/-/g, "/")).getTime() -
              timestamp) /
              1000,
            res.data
          );
          this.bargainGoodsItem = res.data;
        }
      });
    },

    //转化时间
    timediff(total, data) {
      let day = parseInt(total / (24 * 60 * 60)); //计算整数天数
      let afterDay = total - day * 24 * 60 * 60; //取得算出天数后剩余的秒数
      let hour = parseInt(afterDay / (60 * 60)); //计算整数小时数
      let afterHour = afterDay - hour * 60 * 60; //取得算出小时数后剩余的秒数
      let min = parseInt(afterHour / 60); //计算整数分
      let afterMin = total - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60;
      //赋值
      (data.day = day),
        (data.hour = hour),
        (data.min = min),
        (data.afterMin = afterMin);
    },
  },
};
</script>

<style lang='less' scoped>
/* 砍价信息 */
.help-friend-bargain {
  width: 345px;
  margin: 15px 0;
  padding: 12px 10px 14px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .goods-box {
    width: 325px;
    height: 110px;
    background: #fff6e5;
    border-radius: 6px;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
      border-radius: 7px;
      margin-right: 10px;
    }

    .goods-description {
      width: 200px;
      display: flex;
      flex-direction: column;

      .goods-title {
        font-size: 14px;
        font-weight: 600;
        color: #3b2203;
        line-height: 19px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .goods-tag {
        display: flex;
        align-items: center;
        margin: 6px 0 13px;

        .goods-tag-item {
          height: 16px;
          background: #f98d1c;
          border-radius: 2px;
          line-height: 16px;
          padding: 0 3.5px;
          margin-right: 5px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .remaining-time {
        display: flex;
        align-items: center;
        /deep/.uni-countdown__splitor {
          padding: 0 3px;
        }
        span {
          margin-left: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #795e40;
        }
      }
    }
  }

  .progress-text {
    margin: 20px 0;
    display: flex;
    align-items: flex-end;
    line-height: 15px;
    font-size: 15px;
    font-weight: 600;
    color: #4f3323;

    span {
      margin: 0 2.5px;
      line-height: 21px;
      font-size: 21px;
      color: #dd1e15;
    }
  }
  .van-progress {
    border-radius: 6px;
    width: 311px;
    overflow: hidden;
    height: 12px;
  }

  /deep/.uni-progress {
    border-radius: 6px;
    width: 311px;
    overflow: hidden;
    height: 12px;

    .uni-progress-bar {
      height: 12px !important;
      background-color: #ffdbb4 !important;
    }

    .uni-progress-inner-bar {
      border-radius: 6px;
      background: linear-gradient(90deg, #ff973c, #ff6836);
    }
  }

  .tip {
    margin-top: 13px;
    display: flex;
    align-items: center;

    /deep/.uni-countdown__number {
      font-size: 12px;
      padding: 0;
    }

    span {
      position: relative;
      top: -1px;
      margin: 0 5px;
      line-height: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #444444;
    }
  }
}
/* 砍价信息end */

/* 砍价记录 */
.bargain-record {
  width: 345px;
  background: #fffcf5;
  box-shadow: 0 0 15px 0 #ff4e00;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 15px;

  .bargain-record-head-title {
    width: 345px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #ffe7bf;
    font-size: 17px;
    font-weight: 600;
    color: #dd1e15;
    text-shadow: 0 0.5px 2px rgba(255, 226, 205, 0.5);
  }

  .bargain-record-list {
    .record-list-item {
      margin: 18px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .friend-image {
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }

      .friend-information {
        position: absolute;
        left: 46px;
        display: flex;
        justify-content: space-between;
        height: 32px;
        width: 150px;
        flex-direction: column;
        padding: 3px 0;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          color: #5a452d;
        }
      }

      .bargain-moeny {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: #5a452d;
          line-height: 20px;
        }
      }
    }
  }
}

/* 砍价记录end */

/* 心跳动画 */
@keyframes breath {
  0% {
    transform: scale(0.9);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

/* 心跳动画end */

/* 免单列表 */
.forfreeList {
  position: relative;
  width: 345px;
  box-sizing: border-box;
  padding: 18px 17px 12px;
  background: #ffffff;
  box-shadow: 0 0 15px 0 #ff4e00;
  border-radius: 15px;

  .title-image {
    width: 314px;
    height: 60px;
    margin-bottom: 4px;
  }

  .goods-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .goods-item {
      width: 150px;
      height: 217px;
      background: url("../../assets/bargain/tjf_kjmfn_wydpk_sp_bg_img.png")
        no-repeat;
      background-size: 100% 100%;
      margin-bottom: 13px;
      align-items: center;
      display: flex;
      padding: 8px 0 12px;
      box-sizing: border-box;
      flex-direction: column;

      .goods-volume {
        font-size: 12px;
        font-weight: 600;
        color: #4e2d01;

        span {
          font-size: 14px;
          color: #dd1e15;
          margin: 0 1.5px;
        }
      }

      .goods-title {
        width: 120px;
        font-size: 15px;
        font-weight: 600;
        color: #3b2203;
        margin: 17px 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .goods-image {
        width: 95px;
        height: 95px;
      }

      .forfree-take {
        margin-top: 10px;
        width: 120px;
        line-height: 33px;
        text-align: center;
        height: 33px;
        background: linear-gradient(180deg, #fd693b, #f54627);
        box-shadow: 0 2px 3px 0 rgba(255, 98, 0, 0.57);
        border-radius: 16px;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
      }

      .forfree-take-hover {
        background: linear-gradient(
          180deg,
          rgba(253, 105, 59, 0.8),
          rgba(245, 70, 39, 0.8)
        );
      }
    }
  }
}

/* 免单列表end */
</style>
